import React, { Component } from 'react';
import './css/About.css';
import border2 from './images/contact_border.jpg';

class Contact extends Component {
    render() { 
        return ( 
            <div className="contactContainer">
                <div>
                    <div className="textContainer">
                        <h4>Whitehead's Contact Information:</h4><br />
                        <table>
                            <tbody>
                            <tr>
                                <td colSpan="2">The best way to contact me is by one of the methods below.  
                                    I generally get email every couple hours and always have my phone next to me.  
                                    Be sure to follow me on <a href="https://www.instagram.com/bryanswhitehead/" target="new">Instagram</a> as well 
                                    as <a href="https://www.facebook.com/whitehead.bryan" target="new">facebook</a> for the most up-to-date art related content.
                                    It has been about a dozen years since I last received a form message, but if you want to send a message go to 
                                    my <a href="http://www.whiteheadgallery.com/mailform/form.html" target="new">legacy form mailer</a>.  
                                    </td>
                            </tr>
                            <tr>
                                <td height="25px"> </td>
                            </tr>
                            <tr>
                                <td className="right">Phone (USA):</td><td className="left">513 299-3032</td>
                            </tr>
                            <tr>
                                <td className="right">Phone (Poland):</td><td className="left">5<s>19 464 043</s></td>
                            </tr>
                            <tr>
                                <td className="right">Email:</td><td className="left"><a href="mailto:bryanswhitehead@gmail.com">bryanswhitehead@gmail.com</a></td>
                            </tr>
                            <tr>
                                <td className="right">Telepathy</td><td className="left">Focus on: <span className="telepathy">&#10629; &#164; &#222; &#8536; &#8785; &#10753;</span></td>
                            </tr>
                            <tr>
                                <td className="right">Instagram</td><td className="left"><a href="https://www.instagram.com/bryanswhitehead/" target="new">Instagram</a></td>
                            </tr>
                            <tr>
                                <td className="right">Facebook</td><td className="left"><a href="https://www.facebook.com/whitehead.bryan" target="new">Facebook</a></td>
                            </tr>
                            <tr>
                                <td className="right">Messenger (fb)</td><td className="left"><a href="http://m.me/whitehead.bryan/" target="new">Messenger Me</a></td>
                            </tr>
                            </tbody>
                        </table> 
                        <br /><br /><br />
                    </div>
                    <div className="textContainer-Mobile">
                        <div className="contact-item"><h3>Whitehead's Contact Information:</h3>
                        <p>The best way to contact me is by one of the methods below.  
                                    I generally get email every couple hours and always have my phone next to me.  
                                    Be sure to follow me on Instagram as well 
                                    as facebook for the most up-to-date art related content.</p>
                                   </div>
                        
                         <div className="contact-item-rows">
                            <div>513-299-3032 (USA)</div>
                            <div><a href="mailto:bryanswhitehead@gmail.com">bryanswhitehead@gmail.com</a></div>
                            <div>Telepathy&nbsp;&nbsp;&nbsp;&nbsp;<span className="telepathy">&#10629; &#164; &#222; &#8536; &#8785; &#10753;</span></div>
                            <div><a href="https://www.instagram.com/bryanswhitehead/" target="new">Instagram</a></div>
                            <div><a href="https://www.facebook.com/whitehead.bryan" target="new">Facebook</a></div>
                            <div><a href="http://m.me/whitehead.bryan/" target="new">Messenger</a></div>
                        </div>
                        <div className="contact-item">
                        <div>If you want to send a form message: <br/> <a href="http://www.whiteheadgallery.com/mailform/form.html" target="new">legacy form mailer</a>.  </div>
                        </div>
                                              
                    </div>
                </div>
                <div><img src={border2} className="aboutFooter" alt="[footer boats]"/></div>
            </div>
        );
    }
}
 
export default Contact;