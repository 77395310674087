import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomeCard from './HomeCard';

import 'react-slideshow-image/dist/styles.css'
import './css/Home.css';
import image1 from './images/about1.jpg';
import brown from './images/brown.jpg';
import sky from './images/sky.jpg';
import sun from './images/sun.jpg';
import chev_down from './images/chevron_down.png'
import chev_up from './images/chevron_up.png'

import beep from './sounds/rift1.mp3';

const Link = require("react-router-dom").Link;

function Home (props) {
    const [isActive, setActive] = useState(true);
    const [isActive2, setActive2] = useState(false);
    const [isActive3, setActive3] = useState(false);
    const [isActive4, setActive4] = useState(true);
    const [isActive5, setActive5] = useState(false);
    const [isActive6, setActive6] = useState(false);
    const [updatest, setUpdates] = useState([]);
    
    const getData = async () => {
        const { data } = await axios.get('./uploader/get_updates.php');
        setUpdates(data);
    };  
      
    useEffect(() => {
        getData();
    }, []);

    // const sorted = updates.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? 0 : -1));
    
    const updates = [
        // {
        //   "comment": "My family is still in Warsaw and I have been working hard on a couple larger paintings. One is a very strong Maine landscape titled, Middle Dock, and the other is titled, The Exposed. ",
        //   "id": "10",
        //   "dateEntered": "2021-10-12"
        // },
        // {
        //   "comment": "It has been an eventful Fall. We just arrived as a family to the states and things are starting to fit together with our move. Unfortunately, painting will be on hold while we get into life in the Midwest. In the meantime I'll be updating my website and creating some tools on the backend to make updating site content easier.",
        //   "id": "9",
        //   "dateEntered": "2021-11-18"
        // },
        // {
        //   "comment": "Over the past couple months I've been updating this site in the evening. The frontend is React which connects to a MySQL database on the backend via php; it's a mix of new and old-school technologies. The sections got moved around a bit; the blog was removed and a digital art page added. There is a desktop version as well as a mobile version with slightly different content and I added some bonus elements here and there. ",
        //   "id": "8",
        //   "dateEntered": "2021-12-15"
        // },
        // {
        //   "comment": "The updated site (this page) is now live and ready to explore. I'll add some more paintings in the next couple days to the \"other\" and \"landscape\" sections. On a side note I am getting more comfortable with React hooks and have a couple things to share on my github page.",
        //   "id": "5",
        //   "dateEntered": "2021-12-18"
        // },
        // {
        //     "comment": "sdI finished a couple small paintings for the Argosy and I'll be sending those out soon.  Working during the day and painting at night has been working out well; it took some time, but I finished up the companion painting for <a href=\"figure/plague.jpg\" target=\"new\"><span className=\"mainLink\">the Plague</span></a>.  That new painting is titled, <a href=\"figure/crumbling.jpg\" target=\"new\"><span className=\"mainLink\">Crumbling</span></a> and both are available.",
        //     "id": "13",
        //     "dateEntered": "2021-09-09"
        // },
        // {
        //   "comment": "Happy New Year! I added a link section that has some art and non-art related links. I've been building an ebay widget that will pull data from ebay's api; this will allow me to show my ebay auctions here (when I have one going). Fixed a form sending issue by directing the user to the https version of the page. ",
        //   "id": "2",
        //   "dateEntered": "2022-01-03"
        // },
        {
            "comment": "We found a house in Cincinnati, bought it, and moved in; no part of that was an easy task. It is exciting calling Deer Park home now. I went through my paintings that got moved from Warsaw and a number of them will be put up here and/or sent to the Argosy Gallery. I optimized some of the javascript code on the site and put some new code up at github.",
            "id": "5",
            "dateEntered": "2022-04-22"
        },
        {
            "comment": "My digital art has taken a hard turn and I changed the code for that section here as well as changed the name to AI Art; this change will allow me to add more art.",
            "id": "4",
            "dateEntered": "2022-09-20"
        },
        {
            "comment": "Sold a couple of the larger paintings and I've been hard at work on the digital front of things during the holidays.  Lots of new AI art was added and will continue.  I'm strongly considering making some prints available for some of the stronger digital work.  There are some issues with the sorting on this news section and as time allows I'll update it so it can pull data from a content management system.",
            "id": "3",
            "dateEntered": "2022-12-06"
        },
        {
            "comment": "This past month I was elated to win best in show at the Miamisburg Art Gallery in Ohio for Forest Heart.  I also won People's Choice award for, East Towards the Thrumcap (Maine landscape), at The Next Generation show in Bar Harbor, Maine that the Argosy Gallery organized.",
            "id": "2",
            "dateEntered": "2023-09-16"
        },
        {
            "comment": "Merry Christmas.  Paintings have sold quicker than I can keep up and there are lots of new red dots.   Three small paintings are going to the Argosy soon and I have another small one that will go up on instagram in the next week.",
            "id": "1",
            "dateEntered": "2023-12-09"
        },
      ];

    //   console.log('unsorted', updates); 
      updates.sort(function (a, b) {
        return a.id - b.id;
      });

    //   console.log('sorted', updates);

    let audio = new Audio(beep);

    const [openW, setOpen] = useState(false);
    const [openW2, setOpen2] = useState(false);
    const d = new Date();
    let year = d.getFullYear();
    
    (( isActive && isActive2 && isActive3 ) || ( isActive4 && isActive5 && isActive6 )) && audio.play();   
        return ( 
            <>
            <div className="Mobile">
                <div className="homeContainer brands-list">
                <table border="0" className="big-table" height="100%">
                    <tbody>
                    <tr>
                        <th height="85" colSpan="9"></th>
                    </tr>
                    <tr>
                        <td width="10%"  height="13%" rowSpan="2"></td>
                        <td width="10%"></td>
                        <td className="b" width="40%" height="13%" rowSpan="2" colSpan="3"><span className="color-animate-7">Welcome</span></td>
                        <td className="b" width="40%" height="26%" rowSpan="3" colSpan="4"><Link to="/Landscape" className="color-animate-1">Landscapes</Link></td>
                    </tr>
                    <tr>
                        <td className="rb"></td>
                    </tr>
                    <tr>
                        <td className="r" rowSpan="3" colSpan="2"><Link to="/Landscape" className="color-animate-2">Maine</Link></td>
                        <td width="17%"></td>
                        <td width="12%"></td>
                        <td width="11%" className="b"></td>
                    </tr>
                    <tr>
                        <td rowSpan="2" colSpan="3"><Link to="/Figure" className="color-animate-3">Figures</Link></td>
                        <td rowSpan="2" width="7%"></td>
                        <td width="12%"></td>
                        <td colSpan="2" rowSpan="2" width="21%" height="12%" className="b"></td>
                    </tr>
                    <tr>
                        <td className="rblb" style={{backgroundImage:`url(${sun})`, opacity:`${isActive ? ".7" : ".2"}`}} onClick={() => {setActive(!isActive)}}>
                        </td>
                    </tr>
                    <tr>
                        <td className="trb" colSpan="2" style={{backgroundImage:`url(${sky})`, opacity:`${isActive2 ? ".7" : ".2"}`}} onClick={() => {setActive2(!isActive2)}} >
                        </td>
                        <td className="b"></td>
                        <td colSpan="4" className="r"></td>
                        <td colSpan="2" height="8%"><Link to="/Art" className="color-animate-4">Oil</Link></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="rb"></td>
                        <td colSpan="5" className="rb"><Link to="/Art" className="color-animate-5">Paintings</Link></td>
                        <td colSpan="2" className="b" height="9%"></td>
                    </tr>
                    <tr>
                        <td rowSpan="2" colSpan="2" className="rb"></td>
                        <td rowSpan="2" colSpan="3" className="rb" onClick={() => {setActive3(!isActive3)}} style={{backgroundImage:`url(${brown})`, opacity:`${isActive3 ? ".7" : ".2"}`}} >
                            
                        </td>
                        <td rowSpan="2" colSpan="2" className="trb"></td>
                        <td colSpan="2" className="b"></td>
                    </tr>
                    <tr>
                        <td className="b" ></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td rowSpan="2" colSpan="2" className="r" height="12%"></td>
                        <td colSpan="5" className="r"><Link to="/Digital" className="color-animate-6">Digital Art</Link></td>
                        <td rowSpan="2" colSpan="2" valign="top"
                        style={{opacity:`${(isActive && isActive2 && isActive3) ? ".9" : ".1"}`}} ><div className="redBox"></div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" ></td>
                    </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <div className="NotMobile">
                <div className="mainContent" >
                    <HomeCard />
                    <div className="spacer" />
                    
                    
                    <div className="clickOpen"  onClick={() => {setOpen2(!openW2)}}>
                        Bryan Scott Whitehead
                    </div>
                    { !openW2 && (
                        <div className="news">
                            <div className="border2" /><div className="border2" /><div className="border2" />
                            <div className="border2" />
                            <div className="itemNoBorder2">
                                <div>Thanks for stopping by.  I encourage you to look around and enjoy my paintings and digital art.   
                                    I've been a professional artist for over 20 years and this site has been one of several outlets for my work during that time; my versatility, 
                                    desire to create and connect ideas, and strong sense of composition have led me both into art and IT development and I like to mix the two 
                                    in new ways as much as possible.  My visual work is based on decades of study in Maine and Europe and my goals oscillate between representational 
                                    and non-objective subjects that are aesthetically coherent.
                                </div>
                                <div>
                                    <img src={image1} className="floatRight" alt="[Bryan wih Olek]" />
                                </div>
                            </div>
                            <div className="border2" />
                            <div className="border2" /><div className="border2" /><div className="border2" />
                         
                            {/*<b>November 17, 2020:</b>&nbsp;&nbsp;Shipping has been open for a couple months and the <a href="http://www.argosygallery.com/" target="new"><span className="mainLink">Argosy Gallery</span></a> has 6 new paintings and a good number of others.  Several more weeks and I'll be done with school; I was really quite fortunate to be able to take 5 needed classes over the summer and make a substitution and pick up a 6th class at Husson this fall.<br /><br />
                            <b>June 16, 2020:</b>&nbsp;&nbsp;I've got a small number of paintings waiting for shipment to the Argosy Gallery; international shipping (air) is still closed from Poland to the US.  I made a couple boring fixes to this site and had my 100,000th visitor a couple months ago - very exciting.   Its looking like my decision to finish school during covid19 was a good idea, but I can't wait to get in front of the easel again.<br /><br />
                            <b>April 5, 2020:</b>&nbsp;&nbsp;I hope everyone is well and able to stay safe during this diffilcult time.  Paintings can't be sold and shipped right now so I've decided to double up on classes and finish my Computer Science degree this coming fall. I might get a couple weeks here and there to paint, but it depends on how Covid19 plays out.<br /><br />
                            */}
                        </div>
                    )}
                    <div className="spacer" />
                    <div className="clickOpen"  onClick={() => {setOpen(!openW)}}><div>News</div>
                    <div>
                        { !openW ? ( <img src={chev_down} /> ) : ( <img src={chev_up} /> ) } </div></div>
                    { openW && (
                        <div className="news">
                            <div className="border2" /><div className="border2" /><div className="border2" />
                            <div className="border2" />
                            <div className="itemNoBorder">
                                <table>
                                    <tbody>
                                        {updates.map((item, index) => {
                                        const tempDate = item.dateEntered.split('-');
                                        const dateRet = `${tempDate[1]}/${tempDate[2]}/${tempDate[0]}`;
                                        return (
                                            <tr>
                                                <td key={index} className="Twhite" valign='top'>{dateRet}</td>
                                                <td dangerouslySetInnerHTML={{__html: item.comment}} className="leftA" />
                                            </tr>
                                        )
                                        })}
                                    </tbody>
                                </table>
                               
                            </div>
                            <div className="border2" />
                            <div className="border2" /><div className="border2" /><div className="border2" />
                         
                            {/*<b>November 17, 2020:</b>&nbsp;&nbsp;Shipping has been open for a couple months and the <a href="http://www.argosygallery.com/" target="new"><span className="mainLink">Argosy Gallery</span></a> has 6 new paintings and a good number of others.  Several more weeks and I'll be done with school; I was really quite fortunate to be able to take 5 needed classes over the summer and make a substitution and pick up a 6th class at Husson this fall.<br /><br />
                            <b>June 16, 2020:</b>&nbsp;&nbsp;I've got a small number of paintings waiting for shipment to the Argosy Gallery; international shipping (air) is still closed from Poland to the US.  I made a couple boring fixes to this site and had my 100,000th visitor a couple months ago - very exciting.   Its looking like my decision to finish school during covid19 was a good idea, but I can't wait to get in front of the easel again.<br /><br />
                            <b>April 5, 2020:</b>&nbsp;&nbsp;I hope everyone is well and able to stay safe during this diffilcult time.  Paintings can't be sold and shipped right now so I've decided to double up on classes and finish my Computer Science degree this coming fall. I might get a couple weeks here and there to paint, but it depends on how Covid19 plays out.<br /><br />
                            */}
                        </div>
                    )}

                </div>
                <div className="homeContainer brands-list">
                    <table border="0" className="big-table" height="100%">
                        <tbody>
                        <tr>
                            <th height="85" colSpan="12"></th>
                        </tr>
                        <tr>
                            <td width="5%"></td>
                            <td width="5%"></td>
                            <td width="5%"></td>
                            <td width="5%"></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td width="5%"></td>
                            <td width="5%"></td>
                            <td width="5%"></td>
                            <td width="5%"></td>
                        </tr>
                        <tr>
                            <td width="5%"  height="18%" className="b"></td>
                            <td width="95%"  height="" colSpan="11"></td>
                        </tr>
                        <tr>
                            <td width="90%"  height="18%" colSpan="10"></td>
                            <td width="10%"  height="" colSpan="2" className="b" ></td>
                        </tr>
                        <tr>
                            <td width="20%"  height="12%" colSpan="4" className="b"></td>
                            <td width="80%"  height="" colSpan="8"></td>
                        </tr>
                        
                        <tr>
                            <td width="10%"  height="10%" colSpan="2" className="b"></td>
                            <td width="90%"  height="" colSpan="10"></td>
                        </tr>
                        <tr>
                            <td width="15%"  height="7%" colSpan="3" className="b"></td>
                            <td width="60%"  height="" colSpan="5"></td>
                            <td width="25%"  height="" colSpan="4" className="b"></td>
                        </tr>
                        <tr>
                            <td width="15%"  height="12%" colSpan="3" rowSpan="2" className="rb"
                            style={{backgroundImage:`url(${sky})`, opacity:`${isActive4 ? ".4" : ".1"}`}} ><div className="click" 
                            onClick={() => {setActive4(!isActive4)}}></div></td>
                            <td width="10%"  height="" colSpan="2" rowSpan="2" className="b"></td>
                            <td width="30%"  height="" rowSpan="2" ></td>
                            <td width="10%"  height="" rowSpan="3" ></td>
                            <td width="15%"  height="" rowSpan="2" ></td>
                            <td width="15%"  height="" colSpan="3" rowSpan="2" className="b" ></td>
                            <td width="5%"  height="" className="b" style={{opacity:`${(isActive4 && isActive6 && isActive5) ? ".9" : ".1"}`, 
                            backgroundColor:"#c00000", borderLeft: "3px solid black"}} ></td>
                        </tr>
                        <tr>
                            <td width="5%"  height="6%" className="b"></td>
                        </tr>
                        <tr>
                            <td width="15%"  height="5%" colSpan="3" className="rb"></td>
                            <td width="40%"  height="" colSpan="3" className="b"></td>
                            <td width="15%"  height="" className="rb"></td>
                            <td width="5%"  height="" className="rb"
                            style={{backgroundImage:`url(${brown})`, opacity:`${isActive5 ? ".4" : ".1"}`}} ><div className="click" 
                            onClick={() => {setActive5(!isActive5)}}></div></td>
                            <td width="5%"  height="" className="rb"></td>
                            <td width="5%"  height="" colSpan="2" rowSpan="2" className="b" style={{backgroundImage:`url(${sun})`, opacity:`${isActive6 ? ".4" : ".1"}`}} ><div className="click" 
                        onClick={() => {setActive6(!isActive6)}}></div></td>
                            
                        </tr>
                        <tr>
                            <td width="90%"  height="8%" colSpan="10" className="rb"></td>
                            
                        </tr>
                        <tr>
                            <td width="50%"  height="5%" colSpan="6" className="r"></td>
                            <td width="50%"  height="" colSpan="6" ><span className="footer"> Copyright © {year} Bryan Scott Whitehead</span></td>
                        </tr>
                    
                        </tbody>
                        </table>
                    </div>
                    
                </div>

            </>
        );
}
 
export default Home;