import React from 'react';
import './css/Art.css';
import ArtBanner from './ArtBanner';

const Art = () => {
    return(
        <div className="main-container">
            <div className="column art__container width90">
                <div><h3>Welcome to the Gallery.</h3>
                <p className="art-text"> 
                    The sections below are sorted by availability and date.  Red dots indicate works that are 
                    sold.  Feel free to download, share, or print any of the digital art; clicking those
                    works will bring up a save option.
                </p>
                 <br />
                </div>
                <ArtBanner />
            </div>
        </div>
    );
};

export default Art;