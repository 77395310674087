import React, { Component } from 'react';
import axios from 'axios';
import Arts from './Arts';
import ArtMobile from './ArtMobile';
import './css/body.css';
import getKeyFromIndex from './getKeyFromIndex';

class Other extends Component {
 constructor(props) {
    super(props);
      this.state = {
        data: []
        }
      }

    componentDidMount(){
        axios.get('./uploader/get_donuts_other.php').then(res => { 
            this.setState({data: res.data});
           });      
    }

    render() { 
        const sortedArt = this.state.data.sort((a, b) => (a.year > b.year) ? -1 : 1);
        return (
            <>
            <div className="Mobile">
                <div className="art-title">
                    <div><span className="white">Other</span></div>
                    <div>Tap Art to expand details.</div>
                </div>
                <div className="body-container">
                    <div className="mobile-class"> 
                        {sortedArt.map((result, k) => {
                        return ( <div key={getKeyFromIndex(k, 'ArtMobile')}>
                            <ArtMobile title={result.title} size={result.size} 
                            imgurl={result.imgurl} comment={result.comment} key={result.id} 
                            construction={result.construction} price={result.price} available={result.available} />
                            </div>
                            )
                        })}

                    </div>
                </div>
            </div>
            <div className="NotMobile">
                <div className="body-container">
                    <div className="body-card-container">

                        {sortedArt.map((result, l) => {
                        return ( <div key={getKeyFromIndex(l, 'ArtMobile')}>
                            <Arts title={result.title} size={result.size} imgurl={result.imgurl} construction={result.construction} 
                            comment={result.comment} price={result.price} available={result.available} />
                            </div>
                            )
                        })}

                    </div>
                </div>
            </div>        
         </>
        );
    }
}
 
export default Other;