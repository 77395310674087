import React, { Component } from 'react';
import axios from 'axios';
import ArtsDigital from './ArtsDigital';
import ArtMobileDigital from './ArtMobileDigital';
import './css/body.css';
import getKeyFromIndex from './getKeyFromIndex';



class Digital extends Component {
    //initialize an object's state in a class
 constructor(props) {
    super(props);
      this.state = {
        data: []
        }
      }
      componentDidMount(){
        
        axios.get('./uploader/get_donuts_digital.php').then(res => { 
            this.setState({data: res.data});
           });
        }

        

    render() {
        // const sortedArt = this.state.data.sort((a, b) => (a.year > b.year) ? -1 : 1); 
        
        // const sortedArt = [
        //     {
        //         "id": "40",
        //         "title": "Brooklin Boats Sunset Extreme",
        //         "imgurl": "IMG_9583r1.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "1416x1044",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "55",
        //         "title": "Justyna - Cardboard and Matches",
        //         "imgurl": "389761_3296709733116_1217079758_n.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "vertical",
        //         "size": "648x810",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "56",
        //         "title": "Bass Harbor Only Details",
        //         "imgurl": "BassHarborOnlyDetails.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "1200x893",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "57",
        //         "title": "Bar Noise",
        //         "imgurl": "BarNoise.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "vertical",
        //         "size": "1400x1867",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "58",
        //         "title": "Wild One",
        //         "imgurl": "DSC_1628.jpg",
        //         "comment": "Algorithm 8b",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "vertical",
        //         "size": "1796x2517",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "62",
        //         "title": "Jerusalem artichoke",
        //         "imgurl": "DSC_1681.jpg",
        //         "comment": "Algorithm 8b",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "square",
        //         "size": "3000x3000",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "64",
        //         "title": "Mass Gull",
        //         "imgurl": "DSC_8384r2.jpg",
        //         "comment": "Digital work done in photoshop and filterforge in 2010 and re-rendered in 2022 at higher resolution.",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "2160x1728",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2022"
        //     },
        //     {
        //         "id": "47",
        //         "title": "Peoples of P&G",
        //         "imgurl": "P&G_logo.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "3000x1500",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2021"
        //     },
        //     {
        //         "id": "49",
        //         "title": "Castine Tugboat",
        //         "imgurl": "algorithm12b_brian_boat.jpg",
        //         "comment": "Algorithm 12b",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "2000x1500",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2020"
        //     },
        //     {
        //         "id": "54",
        //         "title": "Blocks and Blender Things",
        //         "imgurl": "blocks1_fv.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "square",
        //         "size": "1500x1500",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2019"
        //     },
        //     {
        //         "id": "53",
        //         "title": "Schoodic Shag",
        //         "imgurl": "DSC_4262r1.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "vertical",
        //         "size": "3256x3924",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2018"
        //     },
        //     {
        //         "id": "51",
        //         "title": "Winter Birches Kabaty Forest",
        //         "imgurl": "IMG_3643r1.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "1008x859",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2016"
        //     },
        //     {
        //         "id": "38",
        //         "title": "Union Terminal Summer evening",
        //         "imgurl": "IMG_0319_vf.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "2055x1573",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2015"
        //     },
        //     {
        //         "id": "42",
        //         "title": "Kabaty Forest Beans",
        //         "imgurl": "IMG_3661r1.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "1008x756",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2015"
        //     },
        //     {
        //         "id": "50",
        //         "title": "Yellow Buoys Port Clyde",
        //         "imgurl": "IMG_3995r1.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "1136x746",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2015"
        //     },
        //     {
        //         "id": "39",
        //         "title": "Looking out over the Glorious Vista",
        //         "imgurl": "100_2221r3.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "648x506",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2014"
        //     },
        //     {
        //         "id": "41",
        //         "title": "Wyoming Church",
        //         "imgurl": "churchr1.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "vertical",
        //         "size": "719x960",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2014"
        //     },
        //     {
        //         "id": "52",
        //         "title": "Painting of Bryan (by algorithm)",
        //         "imgurl": "bryan_abstract.jpg",
        //         "comment": "",
        //         "construction": "",
        //         "category": "digital",
        //         "orientation": "horizontal",
        //         "size": "576x545",
        //         "available": "a",
        //         "price": "0",
        //         "year": "2013"
        //     }
        // ];

        return (
            <>
            <div className="Mobile">
                <div className="art-title">
                    <div><span className="white">Digital</span></div>
                    <div>Tap Art to expand details.</div>
                </div>
                <div className="body-container">
                    <div className="mobile-class"> 
                        {this.state.data.map((result, k) => {
                        return ( <div key={getKeyFromIndex(k, 'ArtMobile')}>
                            <ArtMobileDigital title={result.title} size={result.size} 
                            imgurl={result.imgurl} comment={result.comment} key={result.id} 
                            construction={result.construction} price={result.price} available={result.available} />
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="NotMobile">
                <div className="body-container">
                    {this.state.data.map((result, l) => {
                    return ( <div key={getKeyFromIndex(l, 'ArtMobile')}>
                        <ArtsDigital title={result.title} size={result.size} imgurl={result.imgurl} construction={result.construction} 
                        comment={result.comment} price={result.price} available={result.available} />
                        </div>
                        )
                    })}
                </div>
            </div>        
         </>
        );
    }
}
 
export default Digital;