import React from "react";
import './css/Header.css';
const Link = require("react-router-dom").Link;

class Header extends React.Component {
  //initialize an object's state in a class
   constructor(props) {
     super(props);
       this.state = {
        }
   }



   render() {
    return (
      <>
        <div className="NotMobile">
          <div className="navbar">
            
          <Link to="/">
              <div className="headerLink"><span className="bigLetter">W</span>hitehead<br />&nbsp;&nbsp;<span className="bigLetter">G</span>allery</div>
            </Link>
            <div className="nav-items">
              
                <Link className="link" to="/Art">
                  Art
                </Link>
              
              <Link className="link" to="/About">
                  About
                </Link>
              
                <Link className="link" to="/Contact">
                  Contact
                </Link>
                
                <Link className="link" to="/Links">
                  Links
                </Link>
            </div>
        </div>
      </div>
      <div className="Mobile">
          <div className="navbar-images">
            <Link to="/">
              <div className="headerLink"><span className="bigLetter">W</span>hitehead<br />&nbsp;&nbsp;<span className="bigLetter">G</span>allery</div>
            </Link>
            <div className="nav-items-small">
                <Link className="link-small" to="/Art">
                  <img src="./images/art.png" alt="[art link]" />
                </Link>
              
                <Link className="link-small" to="/About">
                  <img src="./images/about.png"alt="[about link]"  />
                </Link>
              
                <Link className="link-small" to="/Contact">
                  <img src="./images/contact.png" alt="[contact link]" />
                </Link>
            </div>
          </div>
        </div>
      </>
      );
    }


 }
 export default Header;

