import React, { useState } from 'react';
import './css/Backgrounds.css';

function BackgroundCard ({imageURL, stateChanger}) {

    return (
        <>
        <div className="background-card" onClick={() => stateChanger(`${imageURL}`)}>
            <img className="contain"  src={`${imageURL}`} />
        </div>
        </>
    )
}
export default BackgroundCard;
