import React, { useState } from 'react';
import useModal from 'react-hooks-use-modal';

import './css/Art.css';
import Buy from './Buy';

function ArtMobile({ title, size, imgurl, comment, price, construction, available }) {
    const [isActive, setActive] = useState(true);

    const [ModalBuy, openBuy, closeBuy] = useModal('root', {
        preventScroll: false
      });

    let smallimgurl = imgurl.replace(".", "_m.");
    //console.log("img url: " + smallimgurl);
    //{"../digital_art_m/" + smallimgurl}
    return (
        <div className="art-com-container-mobile" >
            <div className="art__container-mobile"  >
                    {available === 'c' && ( <div className="red-dot"></div>)}
                    <div onClick={() => {setActive(!isActive)}}><img src={"http://www.whiteheadgallery.com/uploader/digital_art_m/" + smallimgurl} alt={comment}  /></div>
                    { isActive ? <div> </div>  : 
                    <div className="mobile-caption" onKeyDown={e => e.stopPropagation()}
                    onClick={e => e.stopPropagation()}
                    onFocus={e => e.stopPropagation()}
                    onMouseOver={e => e.stopPropagation()}><br />
                        <span className="title-mobile">{title}</span><br />
                        <strong>Size:</strong> {size}<br />
                        <strong>Construction:</strong> {construction}<br />
                        {available === 'a' ? (<><strong>Price:</strong> {price}<br /></>) : ''}
                        {available === 'b' && (<>Available at the Argosy Gallery<br /></>)}
                        <strong>Comments:</strong> {comment}<br /><br />
                        {available === 'a' ? (<> <div onClick={openBuy} className="purchase">Buy Painting</div>
                        <ModalBuy>
                            <div className="buy-container">
                                <div>
                                    <Buy title={title} price={price} />
                                </div>
                            </div>
                        </ModalBuy></>) : ''}
                       
                        
                    </div>}
                </div>

            
        </div>
    );
}
export default ArtMobile;