import React from 'react';
import './css/Art.css';
import useModal from 'react-hooks-use-modal';
import { saveAs } from 'file-saver';

function ArtsDigital({ title, size, imgurl, comment }) {
    const [Modal, open, close] = useModal('root', {
        preventScroll: true
      });
      
      const downloadImage = () => {
        saveAs(`https://www.whiteheadgallery.com/uploader/digital_art_m/${imgurl}`, `${imgurl}`) 
      }

    let smallimgurl = imgurl.replace(".", "_m.");
    // console.log("img url: " + smallimgurl);
    // {"../digital_art_m/" + imgurl}
    return (
    <div onKeyDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()} onFocus={e => e.stopPropagation()} onMouseOver={e => e.stopPropagation()}>
       <div className="digitalCard" >
            <div className="widthdigital" onClick={open}>
                <img src={"https://www.whiteheadgallery.com/uploader/digital_art_m/" + smallimgurl} alt={comment} className="digitalImage" />
            </div>
            {/* <div className="comment_container_digital">
                <span className="title">{title}</span><br />
                <strong>Size:</strong> {size} pixels<br />
            </div> */}
   
        </div>
        <Modal>
            <div className="digitalCard-container">
                <img className="large-art" src={"https://www.whiteheadgallery.com/uploader/digital_art_m/" + imgurl} alt={`Painting: ${title}`} /><br />
                <div className='titleArea'>{title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{size} pixels</div>
                <div className="row-container">
                    <button className="save__container" href={"https://www.whiteheadgallery.com/uploader/digital_art_m/" + imgurl}  onClick={downloadImage}><div>Save</div></button>
                    <div className="save__container" onClick={close}>Close</div>
                </div>
            </div>
        </Modal>
        <div id="com_cont" className="comment_container">
            <span className="title">{title}</span><br />
            <strong>Size:</strong> {size} pixels<br />
        </div>
    </div>
    );
}
export default ArtsDigital;