import React from 'react';
import './css/About.css';
import image1 from './images/about1.jpg';
import border1 from './images/about_border.jpg';

function About() {
    const d = new Date();
    return ( 
        <div className="aboutContainer-real">
            <div className="column">
                <div className="textContainer-about">
                    <h2>Artist Statement for {d.getFullYear()}</h2><br />
                    <p>Hello, I'd like to personally welcome you to the Whitehead Gallery.<br /><br /><img src={image1} className="portrait" alt="[Bryan wih Olek]" />
                    My goal is to create  works of art based on new ideas, techniques, 
                        and subject matter. My primary medium is oil and my favorite support is stretched canvas or canvas board, 
                        but I also enjoy creating digital art to satisfy my own curiosities  or as a way to explore ideas for physical work.
                        My subjects are what I find aesthetically interesting which includes: Maine landscapes with both natural
                        and man-made objects, representational figures with both abstract and impossible elements, Midwest 
                        landscapes and urban scenes, some eastern European landscapes, and the occasional still life or 
                        portrait. I try to be open and creative with my visions and often use a variety of techniques to
                        build compositions.<br /><br />

                    My markets for sales include word of mouth, The Argosy Gallery in Bar Harbor, Maine, this website, 
                        and I also sell small works on ebay from time to time. I consider sales and profit second to artistic 
                        learning and creative value.<br /><br />

                    I encourage you to look around and enjoy whatever art you see and I truly hope that something I created will 
                        tell a story, take you in, initiate thought, and inspire you to express yourself in some beautiful way.</p>


                    <h2>Education:</h2>


                    <ul>
                        <li>The University of Cincinnati
                            <ul>
                                <li>1991-92 - Fine Art and Art History</li>
                                <li>1995-99 - Computer and Electrical Engineering</li>
                            </ul> 
                        </li>
                        <li>University of Maine at Augusta (2019-2020)
                            <ul>
                                <li>Computer Information Systems - Software Development</li>
                                <li>Graduated with a 4.00 and was awarded the distinguished CIS student award for 2020-21</li>
                            </ul>
                        </li>
                    </ul>
                    
                    <h2>Experience:</h2>
                    <p>Like most artists I've always been attracted to and spent my free time drawing, painting, paying attention 
                        to aesthetics and composition and always enjoy looking at all types of art.</p>
                    <p>In 2000 I started painting in my free time and began selling finished works in 2002. At this time, I 
                        built the first version of the whiteheadgallery website which was based on my previous creative 
                        websites dating back to 1995. Between 2003 and 2005 I participated in several outdoor 
                        shows around Cincinnati and spent most of my summers of that decade in Maine where I could paint full-time.
                        In 2007 I moved to Warsaw, Poland and transitioned to a full-time professional artist; this allowed for
                        tremendous growth and exploration of my techniques and artistic vision. </p>
                    <p>Since 2009 I've been selling work at the Argosy Gallery in Bar Harbor and in 2012, I was the top 
                        selling artist there. From 2002 to 2022 I've completed over 912 paintings with 47 more commissioned 
                        works.</p>
                    <h2>Studio:</h2>
                    
                        <ul>
                            <li>2002-2007: Cincinnati, Ohio</li>
                            <li>2007-2021: Warsaw, Poland</li>
                            <li>2021-present: Cincinnati, Ohio</li>
                        </ul>
                    
                    <h2>Previous shows, awards, and organizations:</h2>
                    <ul>
                        <li>2005: Wyoming Art Show; Cincinnati, Ohio:  <span style={{color: "#ffcb3f"}}>People's' Choice Award</span></li>
                        <li>2004-2007: Signature Member Cincinnati Art Club (showed in numerous shows around Cincinnati)</li>
                        <li>2006-2007: Affiliated with the Miller Gallery in Cincinnati, OH</li>
                        <li>2010-2014: Boston International Fine Art Show; Boston, MA: Group Show</li>
                        <li>2013-2014: Galyn's Restaurant Father's Day art show</li>
                        <li>2010: Acadia Invitational II; Bar Harbor, Maine: Group Show</li>
                        <li>2014: Raymar online art competition for October; <span style={{color: "#ffcb3f"}}>Finalist</span></li>
                        <li>2017: Third Acadia Invitational, Argosy Gallery, Bar Harbor, ME</li>
                        <li>2020: 25th Anniversary Show, Argosy Gallery, Bar Harbor, ME</li>
                        <li>2023: The Next Generation Show, Bar Harbor Inn, Bar Harbor, ME; <span style={{color: "#ffcb3f"}}>People's' Choice Award</span></li>
                        <li>2023: Miamisburg Art Gallery Juried Show; Miamisburg, Ohio; <span style={{color: "#ffcb3f"}}>Best in Show</span></li>
                    </ul>

                
                    <br /><br /><br /><br />
                </div>
            </div>
            <div><img src={border1} className="aboutFooter" alt="[Footer - boats]" /></div>
        </div>
    );
}

export default About;