import React, { Component } from 'react';
import './css/buy.css';
import Axios from 'axios';

class Buy extends Component {
    state = {
            cname: '',
            title: this.props.title, 
            price: this.props.price,
            message: '',
            emailStatus: false,
            sendToEmail: 'bryanswhitehead@gmail.com',
            emptyForm: false
        };
    

        handleChange = input => e => {
            this.setState({[input]: e.target.value});
        }
    
        submitForm = (e) => {
            let {
                cname, phone, email, message, title, price
            } = this.state;

            e.preventDefault();

            if ((email == '') || message == ''){
                this.setState({ emptyForm: true }, () => {
                    setTimeout(() => this.setState({ emptyForm: false }), 15000);
                },[])
            }
            else{
                Axios.post('https://www.whiteheadgallery.com/php/sendmail.php', this.state)
                .then(response => {
                    //console.log(response)
                    this.setState({
                        emailStatus: true
                    })
                    setTimeout(function(){
                        this.setState({emailStatus: false});
                    }.bind(this),15000);  // wait 15 seconds, then reset to false
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }

render() { 
    let {
        cname, email, message, emailStatus, emptyForm, title, price
    } = this.state;

        return (
            <div>
               
                <div>
                    {emailStatus ? <div className="bigtext">Thank you.<br/><br/> Your message was delivered. </div> 
                    : <div><p>Please fill in your contact information in the email section.  I'll get back to you with more information about {this.props.title} soon.</p> </div>}
                    <br/>
                </div>

                        
                        <div className="input-form"> 
                        <form onSubmit={this.submitForm} className="form-horizontal">
                        
                        <input id="textinput" name="cname" type="text" placeholder="Name" className="" required="" 
                                value={cname} onChange={this.handleChange('cname')} />   
                            
                            <input id="textinput" name="email" type="text" placeholder="Email Address or Contact Info" 
                                className={emptyForm ? 'green-border' : ''}
                            value={email} onChange={this.handleChange('email')} /> 

                            <input id="textinput2" name="title" type="text" value={`Painting: ${this.props.title}`} 
                                
                            onChange={this.handleChange('title')} />   
                        
                        <div className="sell-row-textbox">                    
                                    <textarea rows="3" id="textarea" name="textarea" className="fill-width" 
                                    
                                    value={message} onChange={this.handleChange('message')} placeholder="Comments"></textarea>
        
                        </div>
            
                        <div className="submit-row">
                                <div><button type="submit" className="purchase">Send Request</button></div>
                                {emptyForm ? <div className="form-failed">Please enter your contact information so I can get back to you.</div> : <div> </div>}
                        </div>
                            
                            

                        </form>
            
                    </div>
                <div>
                    
                </div>
            
            </div>
         );
    }
}
 
export default Buy;