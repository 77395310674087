import React from "react";
import './css/Art.css';
import {Link} from 'react-router-dom';

class Header extends React.Component {
  //initialize an object's state in a class
   constructor(props) {
     super(props);
       this.state = {
         width: window.innerWidth
        }
   }
      
   render() {
      return (
        <>
          <div className="NotMobile">
            <div className="row link-container">
                <div className="image">
                    <Link to="/Landscape"><img src="./images/landscape.jpg" alt="[Go to Landscapes]" /></Link><h3>Landscapes</h3></div>
                <div className="image">
                    <Link to="/Figure"><img src="./images/figure.jpg" alt="[Go to Figure Paintings]" /></Link><h3>Figures</h3></div>
                <div className="image">
                    <Link to="/Other"><img src="./images/other2.jpg" alt="[Go to Other Paintings]" /></Link><h3>Other</h3></div>
                <div className="image">
                    <Link to="/Digital"><img src="./images/digital.jpg" alt="[Go to Digital Art]" /></Link><h3>AI Art</h3></div>
            </div>
          </div>
          <div className="Mobile">
            <div className="row link-container">
                <div className="image-mobile">
                    <Link to="/Landscape"><img src="./images/landscape.jpg" alt="[Go to Landscapes]" /></Link><h3>Landscapes</h3></div>
                <div className="image-mobile">
                    <Link to="/Figure"><img src="./images/figure.jpg" alt="[Go to Figure Paintings]" /></Link><h3>Figures</h3></div>
            </div>
            <div className="row link-container">
                <div className="image-mobile">
                    <Link to="/Other"><img src="./images/other2.jpg" alt="[Go to Other Paintings]" /></Link><h3>Other</h3></div>
                <div className="image-mobile">
                    <Link to="/Digital"><img src="./images/digital.jpg" alt="[Go to Digital Art]" /></Link><h3>AI Art</h3></div>
            </div>
          </div>
        </>
    
      );
   };
 }
 export default Header;

