import React, { Component } from 'react';
import './css/About.css';
import border1 from './images/about_border.jpg';
const Link = require("react-router-dom").Link;

class Links extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="aboutContainer-real">
                <div className="column">
                    <div className="textContainer-about">
                    <h2>Art related links:</h2><br />
                    <div className="center">
                        <ul>
                        <li>
                                    <a href="https://fineartamerica.com/art/bryan+whitehead" target="new">
                                        Prints available at FineArtAmerica
                                    </a>
                                </li>
                                <li>
                                    <Link to="/Backgrounds">
                                        Digital backgrounds - seamless or large
                                    </Link>
                                </li>
                                
                                <li>
                                    <a href="https://filterforge.com/filters/author14083-page1.html" target="new">
                                        Some of my filterforge work
                                    </a>
                                </li>
                                
                            </ul>
                    </div>
                        
                    <h2>Other fun links:</h2><br />
                    <div className="center">
                        <ul>
                            <li><a href="https://github.com/bryanwhitehead?tab=repositories" target="new">
                                    My Github repositories</a>
                                    </li>

                            <li>
                                <a href="http://www.whiteheadgallery.com/temp/calc_py.html" target="new">
                                    P&G Practice Assessment calculator</a>
                                
                            </li>
                            <li>
                                <a href="http://www.whiteheadgallery.com/temp/baseConvertor.html" target="new">
                                    Base number convertor</a>
                                
                            </li>
                        </ul>
                    </div>

                    


                    <br /><br /><br /><br />
                    </div>
                </div>
                <div><img src={border1} className="aboutFooter" alt="Footer - boats]" /></div>
            </div>
        );
    }
}
 
export default Links;