import React, { Component } from 'react';
import axios from 'axios';
import Arts from './Arts';
import ArtMobile from './ArtMobile';
import './css/body.css';
import getKeyFromIndex from './getKeyFromIndex';


class Figure extends Component {
   //initialize an object's state in a class
   constructor(props) {
    super(props);
      this.state = {
        data: []
        }
      }
      //  "homepage": "http://www.whiteheadgallery.io/wg3",
      // http://www.whiteheadgallery.io/php/get_donuts_m.php
      componentDidMount(){
        axios.get('./uploader/get_donuts_m.php').then(res => { 
            this.setState({data: res.data.sort((a, b) => (a.year > b.year) ? -1 : 1)});
           });
           
        }     

  render() {
   //const sortedArt = this.state.data.sort((a, b) => (a.year > b.year) ? -1 : 1);
//    console.log('sorted', this.state.data);

// const tempData = [
//   {
//     "id": "63",
//     "title": "Our Fossilized Thoughts",
//     "imgurl": "DSC_2032.jpg",
//     "comment": "This is my mental image of the remnants of our thoughts, aspirations, and dreams.  Painted over a number of months from ideas developed during our pandemic.  ",
//     "construction": "Oil on Canvas",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "20x16 inches",
//     "available": "a",
//     "price": "800",
//     "year": "2022"
//   },
//   {
//     "id": "28",
//     "title": "The Exposed",
//     "imgurl": "theExposed.jpg",
//     "comment": "Exploration into some geometric forms and unreal settings.  I wanted several levels of interest in the background to go along with an intense reflective shattered figure object.  Painted with a dry brush technique over a couple weeks.",
//     "construction": "Oil on Canvas",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "30x24 inches",
//     "available": "a",
//     "price": "3500",
//     "year": "2021"
//   },
//   {
//     "id": "24",
//     "title": "Crumbling",
//     "imgurl": "crumbling.jpg",
//     "comment": "Companion painting for The Plague. This was painted with a dry on dry technique (like the other) with concern for subject materials and composition. Preliminary work for this was done in Blender (3d modeling tool) which helped me visualize the 3d forms before putting paint down. ",
//     "construction": "Oil on Canvas",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "30x24 inches",
//     "available": "a",
//     "price": "3500",
//     "year": "2021"
//   },
//   {
//     "id": "22",
//     "title": "The Plague",
//     "imgurl": "plague.jpg",
//     "comment": "Originally intended to be part of my personal collection; this work is now available because of our Covid19 pandemic. Partly inspired by works done by the master, Zdzislaw Beksinski. Without worry about stock or subject I just sat at the easel and let technique and composition run and then stopped as I reached my mental goals. More to come like this. ",
//     "construction": "Oil on Canvas",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "30x24 inches",
//     "available": "a",
//     "price": "3500",
//     "year": "2021"
//   },
//   {
//     "id": "34",
//     "title": "Fast Figure Study ",
//     "imgurl": "f21.jpg",
//     "comment": "Quick oil painting.  My goal with this work was building forms with an exaggerated palette and to accentuate edges with stroke.  I was considering using this style for other compositions at the time.",
//     "construction": "Oil on Board",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "8x6 inches",
//     "available": "a",
//     "price": "175",
//     "year": "2017"
//   },
//   {
//     "id": "32",
//     "title": "Rodrick",
//     "imgurl": "o65.jpg",
//     "comment": "Painting of my buddy Rodrick.   He painted me and I painted him.  ",
//     "construction": "Oil on Board",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "12x16 inches",
//     "available": "a",
//     "price": "250",
//     "year": "2012"
//   },
//   {
//     "id": "31",
//     "title": "Alan Bamberger",
//     "imgurl": "o58.jpg",
//     "comment": "Painting of consultant and artist advisor Alan Bamberger.   He runs the artbusiness.com website and shares various art related bits on facebook.   I did this painting for fun to explore some new techniques I worked on from 2012 to 2015.   ",
//     "construction": "Oil on Board",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "14x11",
//     "available": "a",
//     "price": "450",
//     "year": "2012"
//   },
//   {
//     "id": "29",
//     "title": "Polish Girl Study",
//     "imgurl": "polishgirlstudy.jpg",
//     "comment": "Painted started in 2011 with concern on aesthetics using a slightly enhanced palette in the shadows and background areas.  Brushwork refined in detail areas and rather loose outside of focus.",
//     "construction": "Oil on Canvas",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "30x24 inches",
//     "available": "c",
//     "price": "0",
//     "year": "2011"
//   },
//   {
//     "id": "30",
//     "title": "John Henry",
//     "imgurl": "john_henry_painting.jpg",
//     "comment": "Strong composition of folk legend, John Henry.  I completed this work over a couple months as I transitioned from being a part-time painter to a full time artist.  Several techniques were used here, but I mostly relied on form simplification, paint extraction techniques, and dry brush.  Completed in 2007.",
//     "construction": "Oil on Canvas",
//     "category": "figure",
//     "orientation": "vertical",
//     "size": "6 x 4 feet",
//     "available": "a",
//     "price": "5500",
//     "year": "2007"
//   }
// ];
    return (
        <>
        <div className="Mobile">
            <div className="art-title">
                <div><span className="white">Figurative Paintings</span></div>
                <div>Tap Art to expand details.</div>
            </div>
            <div className="body-container">
                <div className="mobile-class"> 
                    
                    {this.state.data.map((result, k) => {
                    return ( <div key={getKeyFromIndex(k, 'ArtMobile')}>
                        <ArtMobile title={result.title} size={result.size} 
                        imgurl={result.imgurl} comment={result.comment} key={result.id} 
                        construction={result.construction} price={result.price} available={result.available} />
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
        <div className="NotMobile">
            <div className="body-container">
                <div className="body-card-container">
                    {this.state.data.map((result, l) => {
                    return ( <div key={getKeyFromIndex(l, 'ArtMobile')}>
                        <Arts title={result.title} size={result.size} imgurl={result.imgurl} construction={result.construction} 
                        comment={result.comment} price={result.price} available={result.available} />
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>        
     </>
    );
  };
}
export default Figure;