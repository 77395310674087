import React, { Component } from 'react';
import axios from 'axios';
import Arts from './Arts';
import ArtMobile from './ArtMobile';

import './css/body.css';
import getKeyFromIndex from './getKeyFromIndex';

class Landscape extends Component {
 //initialize an object's state in a class
 constructor(props) {
  super(props);
    this.state = {
      data: []
      }
    }
    //  "homepage": "http://www.whiteheadgallery.io/wg3",
    // http://www.whiteheadgallery.io/php/get_donuts_m.php
    componentDidMount(){
      
      axios.get('./uploader/get_donuts_landscape.php').then(res => { 
          this.setState({data: res.data.sort((a, b) => (a.year > b.year) ? -1 : 1)});
         });
          
      }

   render() {
    //const isMobile = width <= 800 ? true : false;
    //const sortedArt = this.state.data.sort((a, b) => (a.year > b.year) ? -1 : 1);
     return (
        <div className="spacerTop">
            <div className="Mobile">
                <div className="art-title">
                    <div><span className="white">Landscape Paintings</span></div>
                    <div>Tap Art to expand details.</div>
                </div>
                <div className="body-container">
                    <div className="mobile-class"> 
                     {/*
                     <ArtMobileTemp title="title" size="size"
                            imgurl="url" comment="comment" key="us" 
                            construction="construction" price="rpice" available="a" />   
*/}
                       
                        {this.state.data.map((result, k) => {
                        return ( <div key={getKeyFromIndex(k, 'ArtMobile')}>
                            <ArtMobile title={result.title} size={result.size} 
                            imgurl={result.imgurl} comment={result.comment} key={result.id} 
                            construction={result.construction} price={result.price} available={result.available} />
                            </div>
                            )
                        })}

                    
                    </div>
                </div>
            </div>
            <div className="NotMobile">
            
                <div className="body-container">
                    <div className="body-card-container">

                         {/*}
                        <ArtTemp />
                        */}

                       
                        {this.state.data.map((result, l) => {
                        return ( <div key={getKeyFromIndex(l, 'ArtMobile')}>
                            <Arts title={result.title} size={result.size} imgurl={result.imgurl} construction={result.construction} 
                            comment={result.comment} price={result.price} available={result.available} />
                            </div>
                            )
                        })}
                    
                    </div>
                </div>
            </div>        
        </div>
     );
   
   };
}
export default Landscape;