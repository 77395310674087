import React from "react";
import ReactDOM from "react-dom";

import Header from "./Header";

import "./styles.css";
import Home from './Home';
import Art from './Art';
import Contact from './Contact';
import About from './About';
import Landscape from './Landscape';
import Figure from './Figure';
import Other from './Other';
import Digital from './Digital';
import Links from './Links';
import Backgrounds from './Backgrounds';


import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from 'react-router-dom/Route';


function App() {
  return (
    <div>
      
      <Router>
        <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/art" component={Art} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/landscape" component={Landscape} />
            <Route path="/figure" component={Figure} />
            <Route path="/other" component={Other} />
            <Route path="/digital" component={Digital} />
            <Route path="/links" component={Links} />
            <Route path="/backgrounds" component={Backgrounds} />
          </Switch>
      </Router>

    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);