import React from 'react';
import Buy from './Buy';
import './css/Art.css';
import useModal from 'react-hooks-use-modal';

function Arts({ title, size, imgurl, comment, price, construction, available }) {
    const [Modal, open, close] = useModal('root', {
        preventScroll: true
      });

    const [ModalBuy, openBuy, closeBuy] = useModal('root', {
        preventScroll: false
      });
    
    let smallimgurl = imgurl.replace(".", "_m.");
    // console.log("img url: " + smallimgurl);
    // {"../digital_art_m/" + imgurl}
    return (
        <div className="art-com-container" onKeyDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}
        onMouseOver={e => e.stopPropagation()}>
            <div className="art__container">
            
                <div className="width50" onClick={open}><img src={"http://www.whiteheadgallery.com/uploader/digital_art_m/" + smallimgurl} alt={comment} />
                { available === 'c' && ( <div className="red-dot-notMobile"></div>)}
                </div>
                <div className="comment_container">
                    
                    <span className="title">{title}</span><br />
                    <strong>Size:</strong> {size}<br />
                    <strong>Construction:</strong> {construction}<br />
                    {available === 'a' ? (<><strong>Price:</strong> {price}<br /></>) : ''}
                    {available === 'b' && (<>Available at the Argosy Gallery<br /></>)}
                    <strong>Comments:</strong> {comment}<br /><br />
                    { available === 'a' && ( <div className="purchase" onClick={openBuy}>Purchase</div> )}
                </div>
            </div>
                <Modal>
                    <div className="modal-container">
                        <img className="large-art" src={"http://www.whiteheadgallery.com/uploader/digital_art_m/" + imgurl} alt={`Painting: ${title}`} />
                        <div className="row-container">
                            <div className="save__container" onClick={close}>Close</div>
                        </div>
                    </div>
                </Modal>

                <ModalBuy>
                    <div className="buy-container">
                        <div>
                            <Buy title={title} price={price} />
                        </div>
                    </div>
                </ModalBuy>
        </div>
    );
}
export default Arts;
