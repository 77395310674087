import React, { Component } from 'react';
import './css/Art.css';

class ArtMobileDigital extends Component{
    constructor(){
        super();
        this.state={
            show: true
        }
    }
    
    render(){
    let smallimgurl = this.props.imgurl.replace(".", "_m.");
    //console.log("img url: " + smallimgurl);
    //{"../digital_art_m/" + smallimgurl}
    return (
        <div className="art-com-container-mobile" onClick={()=>{this.setState({show:!this.state.show})}}>
            <div className="art__container-mobile"  >
                    <img src={"http://www.whiteheadgallery.com/uploader/digital_art_m/" + smallimgurl} alt={this.props.comment}  />   
                    { this.state.show? <div></div>  : <div className="mobile-caption"><br />
                    <span className="title-mobile">{this.props.title}</span><br />
                    <strong>Size:</strong> {this.props.size} pixels<br />
                    </div>}
                </div>

            
        </div>
    );
    
    }
}
export default ArtMobileDigital;