import React, { useState, useEffect, useRef } from 'react';
import useModal from 'react-hooks-use-modal';
import getKeyFromIndex from './getKeyFromIndex';
import BackgroundCard from './BackgroundCard';
import './css/Backgrounds.css';


let imageCount = 18;
let images = [];
for (let i = 1; i <= imageCount; i++){
    images.push(`http://www.whiteheadgallery.com/images/b${i}.jpg`);
}

function Backgrounds() {
    const [url, setUrl] = useState('');
    
    useInterval(() => {
        setUrl('');
      }, 15000);

    function useInterval(callback, delay) {
    const savedCallback = useRef();
    
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    
    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
        savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
    }
  

    return (
        <>
        <div className="backgrounds-container" style={{backgroundImage:`url(${url != `` ? `${url}` : ''})`}} />
            <div className="background-card inner">
                <h4>Below are just a couple of the backgrounds I have put together over the years.  The square ones are seamless.  Feel free to download them and share (right click -&gt; save as...)</h4>
            </div><br />
        <div className="backgroundRow">
            
            
        {images.map((image, m) => {
            return (
                <div key={getKeyFromIndex(m, 'bgcard')}>
                 <BackgroundCard stateChanger={setUrl} imageURL={image} />
                </div>
            )
            
        })}
        
        </div>
        </>
        
    );
}
export default Backgrounds;
